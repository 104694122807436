import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
//import { $ } from 'protractor';
declare var $:any;


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  UserName: any;
  UserType: any;
  RoleDesc:any;

  showMst: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    
   this.show();


    this.UserName= sessionStorage.UserName;
    this.UserType= sessionStorage.UserType;
    this.RoleDesc=sessionStorage.RoleDesc;
    this.showMst = true;
    
    if(this.UserType=="ADM"){
      this.UserType="Admin";
    }
    else{
      //this.UserType="User";
    }
    
    if(sessionStorage.MstFlag == 'SM' || sessionStorage.MstFlag == 'LG'){
      this.showMst = false;
    }else{
      this.showMst = true;
    }
  }

  show()
  {
    JSON.parse(sessionStorage.ShowMenu).forEach(element => {
      if($("[formflag="+element+"]").length>0)
      {
        $("[formflag="+element+"]").show();
      }
      else{$("[formflag="+element+"]").hide();}
    });


  }

  logout(){
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);



  }
}
