import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { AppComponent } from './app/app.component';
import { IGridoption } from './app/interface/igridoption';
export function initUiGrid() {

  const uiGridComponent: angular.IComponentOptions = {
    bindings: {
      data: '<',
      onEdit: '&',
      onAdd: '&',
      onDeleteAll: '&',
      onDelete: '&',
      onDpk: '&',
      onTarget: '&',
      gridOptions: '='
    },
    transclude: true,
    controller: ['$scope', 'uiGridConstants', function($scope, uiGridConstants) {

      const ctrl = this;

      const today = new Date();
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 7);

      $scope.highlightFilteredHeader = function (row, rowRenderIndex, col, colRenderIndex) {
        if (col.filters[0].term) {
          return 'header-filtered header_title grid-label-center';
        } else {
          return 'header_title grid-label-center';
        }
      };

      $scope.msg = {};
      $scope.gridOptions = {
        enableFiltering: true,
        showGridFooter: false,
        showColumnFooter: false,
        enableSorting: true,
        treeRowHeaderAlwaysVisible: false,
       
       
        paginationPageSizes: [25,50,100,200,500],
        paginationPageSize: 25,
        headerCellClass: 'white',
        enableColumnResizing:true,
        width: 100,
        exporterMenuPdf: false,
        exporterMenuCsv: true,
        exporterMenuExcel: true,
        enableGridMenu: true,
        enableRowSelection: true,
        enableFullRowSelection :true,
        enableSelectAll: true,
        //exporterCsvFilename:'data.csv',

        //exporterExcelFilename: 'myFile.xlsx',
        exporterExcelSheetName: 'Sheet1',
        exporterExcelSheetStyle: {fontSize: 10, bold: true, italics: true, color: 'red'},
       
        //for pdf
        // exporterPdfDefaultStyle: {fontSize: 9},
        // exporterPdfTableStyle: {margin: [30, 30, 30, 30]},
        // exporterPdfTableHeaderStyle: {fontSize: 10, bold: true, italics: true, color: 'red'},
        // exporterPdfHeader: { text: "My Header", style: 'headerStyle' },
        // exporterPdfFooter: function ( currentPage, pageCount ) {
        //   return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
        // },
        // exporterPdfCustomFormatter: function ( docDefinition ) {
        //   docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
        //   docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
        //   return docDefinition;
        // },
        // exporterPdfOrientation: 'portrait',
        // exporterPdfPageSize: 'LETTER',
        // exporterPdfMaxGridWidth: 500,
        
        exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
        onRegisterApi: function (gridApi) {
          $scope.gridApi = gridApi;
        },
       
       
      //   exporterExcelHeader: function (grid, workbook, sheet, docDefinition) {
      //     // this can be defined outside this method
      //     var stylesheet = workbook.getStyleSheet();
      //     var aFormatDefn = {
      //       "font": { "size": 11, "fontName": "Calibri", "bold": true },
      //       "alignment": { "wrapText": true }
      //     };
      //     var formatterId = stylesheet.createFormat(aFormatDefn);
  
      //     // excel cells start with A1 which is upper left corner
      //     sheet.mergeCells('B1', 'C1');
      //     var cols = [];
      //     // push empty data
      //     cols.push({ value: '' });
      //     // push data in B1 cell with metadata formatter
      //     cols.push({ value: 'My header that is long enough to wrap', metadata: {style: formatterId.id} });
      //     sheet.data.push(cols);
      // },

      };

      

      $scope.editEmployee = function(row){
        ctrl.onEdit({
          // "City":row.City,
          // "Designation":row.Designation,
          // "ID":row.ID,
          // "Name":row.Name
          row
        });
      } 
      $scope.addEmployee = function(row){
        ctrl.onAdd({
          // "City":row.City,
          // "Designation":row.Designation,
          // "ID":row.ID,
          // "Name":row.Name
          row
        });
      } 

      $scope.deleteallEmployee = function(row){
        ctrl.onDeleteAll({
          // "City":row.City,
          // "Designation":row.Designation,
          // "ID":row.ID,
          // "Name":row.Name
          row
        });
      } 
    

      $scope.Dpk = function(row,flag){
        ctrl.onDpk({
          // "City":row.City,
          // "Designation":row.Designation,
          // "ID":row.ID,
          // "Name":row.Name
          row,flag
        });
      } 
      
      $scope.deleteEmployee = function(row){
        ctrl.onDelete({
          // "City":row.City,
          // "Designation":row.Designation,
          // "ID":row.ID,
          // "Name":row.Name
          row
        });
      } 
      $scope.targetEmployee = function(row){
        ctrl.onTarget({
          // "City":row.City,
          // "Designation":row.Designation,
          // "ID":row.ID,
          // "Name":row.Name
          row
        });
      }


      $scope.toggleFiltering = function () {
        $scope.gridOptions.enableFiltering = !$scope.gridOptions.enableFiltering;
      };
      $scope.toggleRowSelection = function () {
        $scope.gridApi.selection.clearSelectedRows();
        $scope.gridOptions1.enableRowSelection = !$scope.gridOptions.enableRowSelection;
        $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
    };

      function makeColDefs(row) {
        const colDefs = [];
        let exist = [];

        for (let i = 0; i < row.length; i++) {

          for (const colName in row[i]) {

            if (exist.indexOf(colName) === -1) {

              colDefs.push({
                'field': colName,
                'width': 200
              });
              exist.push(colName);
            }
          }
        }
        exist = undefined;
        return colDefs;
      }

      this.$onChanges = function(obj: { data: { currentValue: Array<{}>},gridOptions:{ currentValue:IGridoption }}) {
        if (obj.data.currentValue && obj.data.currentValue.length) {
          const colDefs = makeColDefs(obj.data.currentValue);
          $scope.gridOptions.data = obj.data.currentValue;
          if(obj.gridOptions && obj.gridOptions !== undefined){
            Object.keys(obj.gridOptions.currentValue).map(function(key, index) {
              $scope.gridOptions[key] = obj.gridOptions.currentValue[key];
            });
            if(obj.gridOptions.currentValue.columnDefs && obj.gridOptions.currentValue.columnDefs !== undefined){
              $scope.gridOptions.columnDefs = obj.gridOptions.currentValue.columnDefs;
            }
          }else{
            $scope.gridOptions.columnDefs = colDefs; 
          }
        }
      };

      this.$onInit = function() {}

    }],
    template: '<div ui-grid="gridOptions" ui-grid-resize-columns ui-grid-exporter ui-grid-auto-resize ui-grid-pagination class="grid" style="width:100%;"></div>'
  };
  return angular.module('uigridmodule', ['ui.grid',  'ui.grid.selection','ui.grid.exporter','ui.grid.pagination'])
    .component('ui-grid', uiGridComponent)
    .directive('appRoot', downgradeComponent({ component: AppComponent }));
}
