import { Component } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as CryptoJs from '../../node_modules/crypto-js';

import { NotificationService } from '../app/core/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'salesForceAdmin';
  static Smartalert;
  static headers:HttpHeaders;
  static BaseUrl;
  static BaseUrlPDF;
  static DownLoadFile;
  static ImagePath;
  static secureKey;
  static router:Router;
 
  constructor(public router:Router, public Smartalert: NotificationService){
    AppComponent.headers=new HttpHeaders({'content-Type':'application/json',
    Authorization:'Basic ' + btoa('SALESFORCE:SALESFORCE@12345?')});

 

    AppComponent.BaseUrl=environment.BaseUrl; 
    AppComponent.ImagePath=environment.ImagePath; 
    AppComponent.BaseUrlPDF=environment.BaseUrlPDF;
    AppComponent.DownLoadFile=environment.DownLoadFile;
    AppComponent.secureKey = CryptoJs.enc.Utf8.parse(environment.secureKey);

    AppComponent.router=router;
    AppComponent.Smartalert = Smartalert;
  }
}
