import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AlphanumericonlyDirective} from 'src/app/directive/MyDirective/alphanumericonly.directive';
import {AlphaonlyDirective} from 'src/app/directive/MyDirective/alphaonly.directive';
import {DotonlyDirective} from 'src/app/directive/MyDirective/dotonly.directive';
import {MultinumbersDirective} from 'src/app/directive/MyDirective/multinumbers.directive';
import {NumericonlyDirective} from 'src/app/directive/MyDirective/numericdirective.directive';
import {NoRightClickDirective} from 'src/app/directive/MyDirective/no-right-click';
import {NoWhitespaceDirective} from 'src/app/directive/MyDirective/no-whitespace.directive';

// import { OnlynumbersDirective } from './numericonly.directive';
// import { AlphaonlyDirective } from './alphaonly.directive';
// import { AlphanumericonlyDirective } from './alphanumericonly.directive';
// import { DotonlyDirective } from './dotonly.directive';
// import { MultinumbersDirective } from './multinumbers.directive';


@NgModule({
  declarations: [
    //OnlynumbersDirective, 
    NumericonlyDirective,
    AlphaonlyDirective, 
    AlphanumericonlyDirective, 
    DotonlyDirective, 
    MultinumbersDirective,
    NoRightClickDirective,
    NoWhitespaceDirective
  ],
  
  imports: [
    CommonModule
  ],

  exports:[
    //OnlynumbersDirective, 
    NumericonlyDirective,
    AlphaonlyDirective, 
    AlphanumericonlyDirective, 
    DotonlyDirective,
    MultinumbersDirective,
    NoRightClickDirective,
    NoWhitespaceDirective],
})
export class CommondirectiveModule { }
