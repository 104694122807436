import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { OrdinaryServices } from '../Services/ordinary-services.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardian implements CanActivate  {

  constructor(private checkifloggedin:OrdinaryServices, private router:Router){ }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

if(this.checkifloggedin.isLoggedIn())
{
  return true;
}
this.router.navigate(['/auth/login']);
    return false ;
  }
}
