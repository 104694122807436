import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '../app/shared/layout/layout.module';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import {AngularFontAwesomeModule}  from 'angular-font-awesome';
import { ChartsModule } from 'ng2-charts';
import { UigriddashboardDirective } from './directive/uigriddashboard.directive';
import { CommonModule } from '@angular/common';
import { CommondirectiveModule } from './directive/MyDirective/commondirective.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NotificationService } from '../app/core/services/notification.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


@NgModule({
  declarations: [
    AppComponent , UigriddashboardDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    UpgradeModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    ChartsModule,
    CommonModule,
    CommondirectiveModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass:'toast-bottom-right',
      preventDuplicates: true,
      maxOpened: 1,
    }),
    TypeaheadModule.forRoot()
    
  ],
  providers: [NotificationService],
  exports:[  UigriddashboardDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
