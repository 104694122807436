import { Component, OnInit } from '@angular/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: []
})
export class MainLayoutComponent implements OnInit {
public currYear;
public date=new DatePipe ('en-Us');

  constructor() { }

  ngOnInit() {
    this.currYear =new Date();
    this.currYear=this.date.transform
    (this.currYear,  'yyyy');
  }

}
