import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuardian } from './core/guard/auth-guardian.guard';


const routes: Routes = [
  { 
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full"},
 
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle: "Home" },
    
    children: [
      {
        path: "",
        redirectTo: "dashboard/analytics",
        pathMatch: "full",
        canActivate:[AuthGuardian]
      },
      {
        path: "dashboard",
        loadChildren: "./features/dashboard/dashboard.module#DashboardModule",
        data: { pageTitle: "Dashboard" }
      },
      {
        path: "master",
        // s
        loadChildren:()=> import('./features/master/master.module').then(r => r.MasterModule),
        data: { pageTitle: "Master" },
        canActivate:[AuthGuardian]
      },   
      {
        path: "report",
        loadChildren: "./features/report/report.module#ReportModule",
        canActivate:[AuthGuardian]
      },    
      {
        path: "setting",
        loadChildren: "./features/setting/setting.module#SettingModule",
        canActivate:[AuthGuardian]
      },
      {
        path: "transaction",
        loadChildren: "./features/transaction/transaction.module#TransactionModule",
        canActivate:[AuthGuardian]
        
      },  
      {
        path: "dash-report",
        loadChildren: "./features/dashboard-report/dashboard-report.module#DashboardReportModule",
        canActivate:[AuthGuardian]
        
      }, 
      {
        path: "settings",
        loadChildren: "./features/settings/settings.module#SettingsModule",
        canActivate:[AuthGuardian]
        
      },       
   ]
   },
  //  {
  //   path: "dashboard",
  //   component: MainLayoutComponent,
  //   loadChildren: "./features/master/master.module#MasterModule",
  // },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: "./features/auth/auth.module#AuthModule"
  },
  { path: "**", redirectTo: "miscellaneous/error404" }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }




