
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[saNumericonly]'
})
export class NumericonlyDirective {
   constructor(private el:ElementRef) { }
   @HostListener('input',['$event']) OnInputChange(event){
    const initialValue=this.el.nativeElement.value;
    this.el.nativeElement.value=initialValue.replace(/[^0-9]*/g, '');
    if(this.el.nativeElement.value!==initialValue){     
     return false;
    }
  }


  //@HostListener('input', ['$event']) onInputChange(event) {
    // const initialValue=this.el.nativeElement.value;
    // this.el.nativeElement.value=initialValue.replace(/[^0-9]*/g,"");
    // if(this.el.nativeElement.value!==initialValue){
    //  return false;
    // }
    //const pattern = /^[0-9.]*$/;   
    //if (!pattern.test(event.target.value)) {
      //event.target.value = event.target.value.replace(/[^0-9]/g, "");
    //}
 // }

//  @HostListener('input', ['$event']) onInputChange(event) {
//   const initalValue = this.el.nativeElement.value;
//   this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
//   if ( initalValue !== this.el.nativeElement.value) {
//     event.stopPropagation();
//   }
// }




}
