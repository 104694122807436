import { Directive,ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[saMultipleNumbers]'
})
export class MultinumbersDirective {
 
  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    var changeposition= event.currentTarget.selectionStart;
    var stringPart1:string=initalValue.substr(0,changeposition);
    var stringPart2:string=initalValue.substr(changeposition,(initalValue.length)-1);
    this._el.nativeElement.value = initalValue.replace(/[^0/1/2/3/4/5/6/7/8/9/+/,/(/)/-]*/g,'');
  
//check next char
    if(     (stringPart1[(stringPart1.length)-1] === "/")
        ||  (stringPart1[(stringPart1.length)-1] === "," && stringPart1[(stringPart1.length)-2] === ",") 
        ||  (stringPart1[(stringPart1.length)-1] === "-" && (stringPart1[(stringPart1.length)-2] === "-" || stringPart1[(stringPart1.length)-2] === "+")) 
        ||  (stringPart1[(stringPart1.length)-1] === "+" && (stringPart1[(stringPart1.length)-2] === "-" || stringPart1[(stringPart1.length)-2] === "+"))
        ||  (stringPart1[(stringPart1.length)-1] === "(" && stringPart1[(stringPart1.length)-2] === "(")
        ||  (stringPart1[(stringPart1.length)-1] === ")" && stringPart1[(stringPart1.length)-2] === ")")
        ||  (stringPart1[(stringPart1.length)-1] === "=" && stringPart1[(stringPart1.length)-2] === "=")
      ) {this.extraChar( changeposition, stringPart1, stringPart2, event);} 
// check previous char
    if(     (stringPart1[(stringPart1.length)-1] === "," && stringPart2[0] === ",") 
        ||  (stringPart1[(stringPart1.length)-1] === "-" && (stringPart2[0] === "-" || stringPart2[0] === "+"))
        ||  (stringPart1[(stringPart1.length)-1] === "+" && (stringPart2[0] === "-" || stringPart2[0] === "+"))
        ||  (stringPart1[(stringPart1.length)-1] === "(" && stringPart2[0] === "(")
        ||  (stringPart1[(stringPart1.length)-1] === ")" && stringPart2[0] === ")")
        ||  (stringPart1[(stringPart1.length)-1] === "=" && stringPart2[0] === "=")
      )
      {
        var changeposition= event.currentTarget.selectionStart +1;
        var stringPart1:string=initalValue.substr(0,changeposition-1);
        var stringPart2:string=initalValue.substr(changeposition,(initalValue.length));
        this.extraChar( (changeposition), stringPart1, stringPart2, event);
      }
    this.validateNextChar(changeposition, stringPart1, stringPart2, event );
    if( initalValue !== this._el.nativeElement.value) { event.stopPropagation(); }

  }

  extraChar( ChngPos, str1, str2, event )
  {
    this._el.nativeElement.value = str1.substr(0,ChngPos-1) + str2;
    event.currentTarget.selectionStart=ChngPos;
    event.currentTarget.selectionEnd=ChngPos-1;
  
  }

  validateNextChar( ChngPos, str1, str2, event)
  {
    const initalValue = this._el.nativeElement.value;
    var valTruncated=initalValue.substr(0,(initalValue.length)-1);
    var secondCommaPos=valTruncated.lastIndexOf(",");
    var valStr1=initalValue.substr(secondCommaPos+1,valTruncated.length-secondCommaPos-1);
    var valStr2=initalValue.substr(0,secondCommaPos);
    valStr1;
    valStr2;
    var flagOpenBrac        = (valStr1.lastIndexOf("(")>=0  && (valStr1.lastIndexOf(")") < valStr1.lastIndexOf("(")) )  ?true:false;
    var flagPlus            = (valStr1.lastIndexOf("+")>=0  )                                                           ?true                         :false;
    // var flagDash         = (valStr1.lastIndexOf("-")>=0  && valStr1.lastIndexOf("+")!>=0 )                           ?true                         :false;
    // var flagComma        = (valStr1.lastIndexOf(",")>=0  && flagOpenBrac==false)                                     ?true                         :false;
    var flagCloseBrac    = (valStr1.lastIndexOf(")")>=0 )                                                            ?(flagOpenBrac=false , true)  :false;

    switch(initalValue[(initalValue.length)-1])
      {
        case "," :
    
            if (flagOpenBrac || initalValue.lastIndexOf(",")==0 )
            {
              this.extraChar( ChngPos, str1, str2, event )
            }
            else{
              this._el.nativeElement.value = initalValue;
              }break;

        case "+" :

          if (flagPlus)
          {
            this.extraChar( ChngPos, str1, str2, event )
          }
          else{
            this._el.nativeElement.value = initalValue;
            }break;
        
      }

  }

}