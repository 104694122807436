// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

    production: false,


    //Local
    // BaseUrl:'http://192.168.1.207/salesforceapi/api/',
    // authKey:'SALESFORCE:SALESFORCE@12345?',
    // secureKey:'SALESFORCE220899'

    //stagging

    // BaseUrl: 'http://salesforce.sipl.pw/salesforceapi/api/',
    // ImagePath: 'http://salesforce.sipl.pw/salesforceapi/Images/',
    // BaseUrlPDF: 'http://salesforce.sipl.pw/salesforceapi/Email/',
    // DownLoadFile: 'http://salesforce.sipl.pw/salesforceapi/OPFInvoice/',
    // authKey: 'SALESFORCE:SALESFORCE@12345?',
    // secureKey: 'SALESFORCE220899',

    // production

    BaseUrl: 'http://salesmanagement.pushpamgroup.co.in/salesapi/api/',
    ImagePath: 'http://salesmanagement.pushpamgroup.co.in/salesapi/Images/',
    BaseUrlPDF: 'http://salesmanagement.pushpamgroup.co.in/salesapi/Email/',
    DownLoadFile: 'http://salesmanagement.pushpamgroup.co.in/salesapi/OPFInvoice/',
    authKey: 'SALESFORCE:SALESFORCE@12345?',
    secureKey: 'SALESFORCE220899'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
