import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrdinaryServices {

  constructor() { }

isLoggedIn(){

  if (sessionStorage.UserID != null && sessionStorage.UserType != null)
  {
    return true;
  }
 else{
   
  return false;
}

}
}